import React , {useEffect} from 'react'
import './About.css'
import pic from '../../assets/Umair2.jpeg'
import 'aos/dist/aos.css'
import AOS  from 'aos'

function About() {

  useEffect(() => {
    AOS.init({})
   },[])

  return (
    <div data-aos='fade-up' className='About-us' id='aboutUs'>
           <div className='about-continer'>
                  <h2><span>01.</span> About Me</h2>

                  <div className='about-wrapper'>
                      <div className='text-content'>
                          <p>I enjoy creating things that live on the internet. My interest in web development started back in 2020 when I decided to try to build websites. It Helps to experienced In HTML , CSS & JS!</p>
                          <p>Fast-forward to today, I learn different technologies relate to web-based for example React, Next and firebase . Using These Technologies built some advanced Projects</p>
                          <p>Here are a few technologies I’ve been working with recently:</p>
                          
                          <ul>
                              <li>Node JS</li>
                              <li>React js</li>
                              <li>Next js</li>
                              <li>Redux</li>
                              <li>Expres</li>
                              <li>Firebase</li>
                              <li>Mongo Db</li>
                          </ul>
                         
                      </div>
                      <div>
                          <div className='main-cont'>
                                    <div className='img-content'>
                                            <img src={pic} alt='pic' />
                                            <div className='shadow'></div>
                                    </div>
                          </div>
                      </div>
                  </div>
           </div>
           
    </div>
  )
}

export default About