import React, { useEffect, useRef } from 'react'
import './Preloader.css'


function Preloader() {
  const ref = useRef();
  
  useEffect(() => {
        console.log(ref.current.getTotalLength())
  }, [])
  
  return (
    <div className='preloader'>
        <h1>
         <svg className='logo-svg' xmlns="http://www.w3.org/2000/svg" width="831" height="113" viewBox="0 0 831 113" fill="none">
                <mask id="path-1-outside-1_2_4" maskUnits="userSpaceOnUse" x="0.799988" y="0.631989" width="830" height="112" fill="black">
                    <rect fill="white" x="0.799988" y="0.631989" width="830" height="112"/>
                    <path d="M18.904 5.63199V69.136C18.904 78.064 21.064 84.688 25.384 89.008C29.8 93.328 35.896 95.488 43.672 95.488C51.352 95.488 57.352 93.328 61.672 89.008C66.088 84.688 68.296 78.064 68.296 69.136V5.63199H81.4V68.992C81.4 77.344 79.72 84.4 76.36 90.16C73 95.824 68.44 100.048 62.68 102.832C57.016 105.616 50.632 107.008 43.528 107.008C36.424 107.008 29.992 105.616 24.232 102.832C18.568 100.048 14.056 95.824 10.696 90.16C7.43199 84.4 5.79999 77.344 5.79999 68.992V5.63199H18.904Z"/>
                    <path d="M205.068 6.35199V106H191.964V31.696L158.844 106H149.628L116.364 31.552V106H103.26V6.35199H117.372L154.236 88.72L191.1 6.35199H205.068Z"/>
                    <path d="M286.619 83.68H242.843L234.779 106H220.955L257.243 6.20799H272.363L308.507 106H294.683L286.619 83.68ZM282.875 73.024L264.731 22.336L246.587 73.024H282.875Z"/>
                    <path d="M337.426 5.63199V106H324.322V5.63199H337.426Z"/>
                    <path d="M412.608 106L388.704 64.96H372.864V106H359.76V5.63199H392.16C399.744 5.63199 406.128 6.92799 411.312 9.51999C416.592 12.112 420.528 15.616 423.12 20.032C425.712 24.448 427.008 29.488 427.008 35.152C427.008 42.064 424.992 48.16 420.96 53.44C417.024 58.72 411.072 62.224 403.104 63.952L428.304 106H412.608ZM372.864 54.448H392.16C399.264 54.448 404.592 52.72 408.144 49.264C411.696 45.712 413.472 41.008 413.472 35.152C413.472 29.2 411.696 24.592 408.144 21.328C404.688 18.064 399.36 16.432 392.16 16.432H372.864V54.448Z"/>
                    <path d="M498.864 95.344H534V106H485.76V5.63199H498.864V95.344Z"/>
                    <path d="M607.244 83.68H563.468L555.404 106H541.58L577.868 6.20799H592.988L629.132 106H615.308L607.244 83.68ZM603.5 73.024L585.356 22.336L567.212 73.024H603.5Z"/>
                    <path d="M701.683 5.63199V16.288H658.051V50.128H693.475V60.784H658.051V106H644.947V5.63199H701.683Z"/>
                    <path d="M730.614 5.63199V106H717.51V5.63199H730.614Z"/>
                    <path d="M805.795 106L781.891 64.96H766.051V106H752.947V5.63199H785.347C792.931 5.63199 799.315 6.92799 804.499 9.51999C809.779 12.112 813.715 15.616 816.307 20.032C818.899 24.448 820.195 29.488 820.195 35.152C820.195 42.064 818.179 48.16 814.147 53.44C810.211 58.72 804.259 62.224 796.291 63.952L821.491 106H805.795ZM766.051 54.448H785.347C792.451 54.448 797.779 52.72 801.331 49.264C804.883 45.712 806.659 41.008 806.659 35.152C806.659 29.2 804.883 24.592 801.331 21.328C797.875 18.064 792.547 16.432 785.347 16.432H766.051V54.448Z"/>
                </mask>
            <path  d="M18.904 5.63199V69.136C18.904 78.064 21.064 84.688 25.384 89.008C29.8 93.328 35.896 95.488 43.672 95.488C51.352 95.488 57.352 93.328 61.672 89.008C66.088 84.688 68.296 78.064 68.296 69.136V5.63199H81.4V68.992C81.4 77.344 79.72 84.4 76.36 90.16C73 95.824 68.44 100.048 62.68 102.832C57.016 105.616 50.632 107.008 43.528 107.008C36.424 107.008 29.992 105.616 24.232 102.832C18.568 100.048 14.056 95.824 10.696 90.16C7.43199 84.4 5.79999 77.344 5.79999 68.992V5.63199H18.904Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
            <path  d="M205.068 6.35199V106H191.964V31.696L158.844 106H149.628L116.364 31.552V106H103.26V6.35199H117.372L154.236 88.72L191.1 6.35199H205.068Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
            <path d="M286.619 83.68H242.843L234.779 106H220.955L257.243 6.20799H272.363L308.507 106H294.683L286.619 83.68ZM282.875 73.024L264.731 22.336L246.587 73.024H282.875Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
            <path   d="M337.426 5.63199V106H324.322V5.63199H337.426Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
            <path  d="M412.608 106L388.704 64.96H372.864V106H359.76V5.63199H392.16C399.744 5.63199 406.128 6.92799 411.312 9.51999C416.592 12.112 420.528 15.616 423.12 20.032C425.712 24.448 427.008 29.488 427.008 35.152C427.008 42.064 424.992 48.16 420.96 53.44C417.024 58.72 411.072 62.224 403.104 63.952L428.304 106H412.608ZM372.864 54.448H392.16C399.264 54.448 404.592 52.72 408.144 49.264C411.696 45.712 413.472 41.008 413.472 35.152C413.472 29.2 411.696 24.592 408.144 21.328C404.688 18.064 399.36 16.432 392.16 16.432H372.864V54.448Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
            <path  d="M498.864 95.344H534V106H485.76V5.63199H498.864V95.344Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
            <path  d="M607.244 83.68H563.468L555.404 106H541.58L577.868 6.20799H592.988L629.132 106H615.308L607.244 83.68ZM603.5 73.024L585.356 22.336L567.212 73.024H603.5Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
            <path  d="M701.683 5.63199V16.288H658.051V50.128H693.475V60.784H658.051V106H644.947V5.63199H701.683Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
            <path  d="M730.614 5.63199V106H717.51V5.63199H730.614Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
            <path ref={ref} d="M805.795 106L781.891 64.96H766.051V106H752.947V5.63199H785.347C792.931 5.63199 799.315 6.92799 804.499 9.51999C809.779 12.112 813.715 15.616 816.307 20.032C818.899 24.448 820.195 29.488 820.195 35.152C820.195 42.064 818.179 48.16 814.147 53.44C810.211 58.72 804.259 62.224 796.291 63.952L821.491 106H805.795ZM766.051 54.448H785.347C792.451 54.448 797.779 52.72 801.331 49.264C804.883 45.712 806.659 41.008 806.659 35.152C806.659 29.2 804.883 24.592 801.331 21.328C797.875 18.064 792.547 16.432 785.347 16.432H766.051V54.448Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_2_4)"/>
        </svg>
        </h1>
    </div>
  )
}

export default Preloader