import React from 'react'
import './Footer.css'
import {AiOutlineInstagram} from 'react-icons/ai'
import {FiGithub} from 'react-icons/fi'
import {FiTwitter} from 'react-icons/fi'
import {FiLinkedin} from 'react-icons/fi'
import {AiOutlineWhatsApp} from 'react-icons/ai'

function Footer() {
  return (
    <footer>
          <ul>
                <li><a rel="noreferrer" target='_blank' href='https://www.instagram.com/thereact_devo/'><AiOutlineInstagram /></a></li>
                <li><a rel="noreferrer" target='_blank' href='https://github.com/DevoUmair'><FiGithub /></a></li>
                <li><a rel="noreferrer" target='_blank' href='https://www.linkedin.com/in/umair-lafir'><FiLinkedin /></a></li>
                <li><a rel="noreferrer" target='_blank' href='https://twitter.com/CllUmair'><FiTwitter /></a></li>
                <li><a rel="noreferrer" target='_blank' href='https://wa.me/94776344127'><AiOutlineWhatsApp /></a></li>`
          </ul>
          <p>Designed and Built By <span>Mohamad Lafir Umair</span></p>
    </footer>
  )
}

export default Footer