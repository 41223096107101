import React from 'react'
import './Sayhello.css'

function Sayhello() {
  return (
    <div className='syahello'>
           <p className='tittle'>What's Next</p>
           <h2>Get In Touch</h2>
           <p className='des'> I’m  currently looking for new job opportunities, If you intrested to hire me you can send mail or send me your details using above form i'll contact You</p>
           <a  rel="noreferrer" href='mailto:lafirumair2000@outlook.com'><button>Say Hello</button> </a>
    </div>
  )
}

export default Sayhello