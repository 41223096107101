import React , {useEffect, useState} from 'react'
import Banner from './Components/Banner/Banner'
import Fixcontent from './Components/Fixcontent/Fixcontent'
import Nav from './Components/Nav/Nav'
import { motion } from 'framer-motion'
import Navslider from './Components/NavSliderComes/Navslider'
import About from './Components/About/About'
import MyBuild from './Components/MyBuild/MyBuild'
import Notworthy from './Components/Notworthyprojects/Notworthy'
import Contact from './Components/ContactMe/Contact'
import Sayhello from './Components/Sayhello/Sayhello'
import Footer from './Components/Footer/Footer'
import Review from './Components/CustomerReview/Review'

function Home() {
  const [cursorX , setCursorX] = useState();
  const [cursorY , setCursorY] = useState();

  window.addEventListener("mousemove" , (e) => {
      setCursorY(e.pageY)
      setCursorX(e.pageX)
  })

  useEffect(() => {

  },[])

  return (
    <motion.div className={`main` }> 
          <Nav />
          <Banner/> 
          <div style={{left:cursorX +'px'  , top:cursorY+'px'}} className='curosr'></div>
          <Fixcontent />
          <Navslider />
          <About />
          <MyBuild />
          <Notworthy />
          <Review />
          <Contact />
          <Sayhello />
          <Footer />
    </motion.div>
  )
}

export default Home