import React, { useEffect } from 'react'
import './MyBuild.css'
import netflix from '../../assets/Capture.JPG'
import linkedIn from '../../assets/linked1.JPG'
import apollo from '../../assets/apollo.JPG'
import blog from '../../assets/Blog.JPG'
import {FiGithub} from 'react-icons/fi'
import {AiOutlineSelect} from 'react-icons/ai'
import 'aos/dist/aos.css'
import AOS  from 'aos'

function MyBuild() {
    
 

  useEffect(() => {
        AOS.init({})
  },[])

  return (
    <div className='my-build' id='myBuild' >
         <div  className='my-build-continer'>
               <h2><span>02.</span> Some Things I’ve Built</h2>

               <div className='project' data-aos='fade-up'>
                     <div className='prjct-img-content'>
                         <img src={netflix} alt='netflix' ></img>
                         <div className='shadow'></div>
                     </div>

                     <div className='prjct-content'>
                          <p className='fetured'>Featured Project</p>
                          <h3>Netflix Clone</h3>
                          <div className='project-content-p'>
                                 <p>Using Styled-components i built exact UI of Netflix. For This project I use TMDB API for get movie details also using firebase i handle register users so authorized user only access this clone and one more thing i use redux toolkit for manage states</p>
                          </div>
                          <ul className='techno'>
                              <li>React js</li>
                              <li>FireBase</li>
                              <li>Styled Components</li>
                              <li>Redux</li>
                          </ul>

                          <ul className='links'>
                              <li><a rel="noreferrer" target='_blank' href='https://github.com/DevoUmair/Netflix-Clone' > <FiGithub/></a></li>
                              <li><AiOutlineSelect/></li>
                          </ul>
                     </div>
               </div>

               <div  className='project project-template-2' data-aos='fade-up'>

                     <div className='prjct-content'>
                          <p className='fetured'>Featured Project</p>
                          <h3>LinkedIn Clone</h3>
                          <div className='project-content-p'>
                                 <p>In this project I use CSS and styled-components for built excat UI of LinkedIn. No any other functionalities like authentication add posts like these features are not implement in this project</p>
                          </div>
                          <ul>
                              <li>React js</li>
                              <li>CSS</li>
                              <li>Styled Components</li>
                          </ul>

                          <ul className='links'>
                              <li><a rel="noreferrer" target='_blank' href='https://github.com/DevoUmair/LinkedIn-Clone' > <FiGithub/> </a> </li>
                              <li><AiOutlineSelect/></li>
                          </ul>
                     </div>

                     <div className='prjct-img-content'>
                         <img src={linkedIn} alt='linkedin' ></img>
                         <div className='shadow'></div>
                     </div>

               </div>

               <div className='project' data-aos='fade-up'>
                     <div className='prjct-img-content'>
                         <img src={blog} alt='blog'></img>
                         <div className='shadow'></div>
                     </div>

                     <div className='prjct-content'>
                          <p className='fetured'>Featured Project</p>
                          <h3>Blog App</h3>
                          <div className='project-content-p'>
                                 <p>This one is a basic react firebase project for understand each concept of React and Firebadse. Using Firebase i implement basic crud operation like add , update and delete also configure google authentication for handle users</p>
                          </div>
                          <ul>
                              <li>React js</li>
                              <li>FireBase</li>
                              <li>Styled Components</li>
                          </ul>

                          <ul className='links'>
                              <li><a rel="noreferrer" target='_blank' href='https://github.com/DevoUmair/BlogApp' > <FiGithub/>  </a></li>
                              <li><a rel="noreferrer" target='_blank' href='https://blog-app-e29fc.web.app/' > <AiOutlineSelect/> </a></li>
                          </ul>
                     </div>
               </div>

               <div  className='project project-template-2' data-aos='fade-up'>

                    <div className='prjct-content'>
                        <p className='fetured'>Featured Project</p>
                        <h3>Apollo Social Media</h3>
                        <div className='project-content-p'>
                                <p>In this project I use CSS and Tailwind CSS for Build a Beatifull UI for this Apollo Socila Media Platform. There are lot's of feature's added in this project for example you can create post like and commnet your friend post , switch to private account follow and un foillow uses there lot's of functionalities added in this project</p>
                        </div>
                        <ul>
                            <li>React js</li>
                            <li>Tailwind CSS</li>
                            <li>Firebase</li>
                            <li>Context API</li>
                        </ul>

                        <ul className='links'>
                            <li><FiGithub/></li>
                            <li><a rel="noreferrer" target='_blank' href='https://piqosocial-19132.firebaseapp.com/' > <AiOutlineSelect/> </a> </li>
                        </ul>
                    </div>

                    <div className='prjct-img-content'>
                        <img src={apollo} alt='apollo' ></img>
                        <div className='shadow'></div>
                    </div>

                </div>      

         </div>
    </div>
  )
}

export default MyBuild