import { createContext, useContext, useState } from "react";

const protFolioContext = createContext();


function PortfolioContext({children}) {
    const [navStatus , setNavStatus] = useState(false);

  return (
    <protFolioContext.Provider value={{navStatus , setNavStatus}}>
               {children}
    </protFolioContext.Provider>
  )
}

export default PortfolioContext
export const useOurConetext = () => {
    return useContext(protFolioContext);
}