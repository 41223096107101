import React from 'react'
import './Fixcontent.css'
import {AiOutlineInstagram} from 'react-icons/ai'
import {FiGithub} from 'react-icons/fi'
import {FiTwitter} from 'react-icons/fi'
import {FiLinkedin} from 'react-icons/fi'
import {AiOutlineWhatsApp} from 'react-icons/ai'


function Fixcontent() {
  return (
    <div>
         <ul className='fix-content-1'>
             <li><a rel="noreferrer" target='_blank' href='https://www.instagram.com/thereact_devo/'><AiOutlineInstagram /></a></li>
             <li><a rel="noreferrer" target='_blank' href='https://github.com/DevoUmair'><FiGithub /></a></li>
             <li><a rel="noreferrer" target='_blank' href='https://www.linkedin.com/in/umair-lafir'><FiLinkedin /></a></li>
             <li><a rel="noreferrer" target='_blank' href='https://twitter.com/CllUmair'><FiTwitter /></a></li>
             <li><a rel="noreferrer" target='_blank' href='https://wa.me/94776344127'><AiOutlineWhatsApp /></a></li>
         </ul>
         <ul className='fix-content-2'>
             <p><a rel="noreferrer"  href='mailto:lafirumair2000@outlook.com' >Lafirumair2000@outlook.com</a></p>
         </ul>
    </div>
  )
}

export default Fixcontent