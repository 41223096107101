import React , {useEffect} from 'react'
import './Conatact.css'
import {AiOutlineArrowRight} from 'react-icons/ai'
import AOS  from 'aos'
import 'aos/dist/aos.css'

function Contact() {

   useEffect(() => {
      AOS.init({})
   },[])


  return (
    <div className='contact-us' id='contact'>
                <div className='head-con'>
                        <h2>Drop Your Detils For Collabaration</h2>
                        <p>Conatct Me</p>
                </div>

                <form>
                     <div data-aos='fade-up' className='each-feilds'>
                        <input type='text'  />
                        <label>Enter You Name</label>
                     </div>
                     <div data-aos='fade-up' className='each-feilds'>
                        <input type='text'  />
                        <label>Enter You Mail</label>
                     </div>
                     <div data-aos='fade-up' className='each-feilds'>
                        <input type='text' />
                        <label>Subject</label>
                     </div>
                     <div data-aos='fade-up' className='each-feilds'>
                        <input type='text' />
                        <label>Enter Your Phone Number</label>
                     </div>
                     <div data-aos='fade-up' className='each-feilds'>
                        <input type='text' />
                        <label>What's Your Mind</label>
                     </div>
                </form>

                <button data-aos='fade-up' > Send Message <AiOutlineArrowRight /> </button>
    </div>
  )
}

export default Contact