import Home from './Home'
import PortfolioContext from './context/PortfolioContext';
import Preloader from './Components/PreLoader/Preloader';
import { useEffect, useState } from 'react';

function App() {
  const [status , setStus] = useState(false);

  useEffect(() => {
              setTimeout(() => {
                       setStus(true)
              }, [5000])
  } , [])

  return (
    <PortfolioContext >
      {
        !status ?  <Preloader /> :<Home />
      }
    </PortfolioContext>
  );
}

export default App;
