import React from 'react'
import './Review.css'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import {AiOutlineSelect} from 'react-icons/ai'
import causeway from '../../assets/causeway.jpeg'
import rompin from '../../assets/rompin.JPG'
import athlo from '../../assets/athlo.jpg'


function Review() {
  return (
    <div className='review'>
            <div id='testi' className='review-head'>
                            <h2>My Testimonials</h2>
                            <p>What Customer Say</p>
            </div>
            <Splide options={{ perPage:1 , arrows:false , pagination:true , drag:'free',  gap:'2rem' ,autoplay:true , Infinity }}>
                        <SplideSlide>
                                <div  className='Testimonial'>
                                      <div className='head-testi'>
                                             <img src={athlo} alt='roshan' />
                                             <h3>@Roshan Nizam</h3>
                                      </div>
                                      <div className='testi-cont'>
                                          <p>"Designed a website for me, The site that was delivered far exceeded my expectations. Excellent work, very happy and satisfied, <span>I will definitely be working with Umair again.</span> I highly recommend Umair to anyone seeking a 
                                             website developer. You will not be disappointed."</p>
                                           <a  rel="noreferrer" target='_blank' href='https://www.athloindoor.com' ><AiOutlineSelect /></a>  
                                      </div>
                                </div>
                        </SplideSlide>
                        <SplideSlide>
                                <div  className='Testimonial'>
                                      <div className='head-testi'>
                                             <img src={causeway} alt='najath' />
                                             <h3>@Najath Ameer</h3>
                                      </div>
                                      <div className='testi-cont'>
                                          <p>"Great work done by umair with some real effort and passion. Recommended.  <span>satisfied more than expected.</span>  He works close to you with lively editing. Impressive"</p>
                                           <a  rel="noreferrer" target='_blank' href='https://www.causewaycarrental.com/' ><AiOutlineSelect /></a>  
                                      </div>
                                </div>
                        </SplideSlide>
                        <SplideSlide>
                                <div  className='Testimonial'>
                                      <div className='head-testi'>
                                             <img src={rompin} alt='najath' />
                                             <h3>@Francis</h3>
                                      </div>
                                      <div className='testi-cont'>
                                          <p>"Really liked the outcome of the design. <span>looks cool and professional</span>. Thanks for the good work "</p>
                                           <a  rel="noreferrer" target='_blank' href='https://www.rompinanglershomestay.com/' ><AiOutlineSelect /></a>  
                                      </div>
                                </div>
                        </SplideSlide>
            </Splide>
            
    </div>
  )
}

export default Review