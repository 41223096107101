import React from 'react'
import { useOurConetext } from '../../context/PortfolioContext'
import './Navslide.css'
import { motion } from 'framer-motion';
import {AiOutlineInstagram} from 'react-icons/ai'
import {FiGithub} from 'react-icons/fi'
import {FiTwitter} from 'react-icons/fi'
import {FiLinkedin} from 'react-icons/fi'
import {AiOutlineWhatsApp} from 'react-icons/ai'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import jsPDF from 'jspdf'
import myCv from '../../assets/UmairCv.pdf'

function Navslider() {
  const {navStatus , setNavStatus} = useOurConetext();  

  const setNavstatus = () => {
    setNavStatus(false)
  }

  const handleResumeDownload = () => {
    var doc = new jsPDF("portrait" , 'px' , 'a4' , 'false')
    doc.addImage(myCv ,'PDF' , 65,20,500 , 400)
    doc.save('UmairCv.PDF')
  }

  return (
    <div className='sidebar'>
        {
          navStatus &&
          <div>
            <motion.div animate={{right:'100%'}} exit={{right:"-100%"}} initial={{right:"-200%"}} transition={{duration:1 , delay:0.5}} className='slider-1'></motion.div>
            <motion.div animate={{right:'100%'}} exit={{right:"-270%"}} initial={{right:"-270%"}} transition={{duration:1 , delay:0.7}} className='slider-2'></motion.div>
            <motion.div animate={{right:'100%'}} exit={{right:"-200%"}} initial={{right:"-200%"}} transition={{duration:1 , delay:1}} className='slider-3'></motion.div>
            <motion.div animate={{right:'100%'}} exit={{right:"-200%"}} initial={{right:"-200%"}} transition={{duration:1 , delay:1.3}} className='slider-4'></motion.div>
            <motion.div animate={{right:'0%'}} exit={{right:"0%"}} initial={{right:"-100%"}} transition={{duration:1 , delay:1}} className='slider-5'>
                   <ul className='home-links'>
                        <motion.li onClick={setNavstatus} animate={{opacity:1 , x:0}} exit={{opacity:0 , x:'-20px'}} initial={{opacity:0 , x:'-20px'}} transition={{duration:0.5 , delay:2.5}} > <AnchorLink  href='#aboutUs'><span>01.</span>About</AnchorLink ></motion.li>
                        <motion.li onClick={setNavstatus} animate={{opacity:1 , x:0}} exit={{opacity:0 , x:'-20px'}} initial={{opacity:0 , x:'-20px'}} transition={{duration:0.5 , delay:3}}  > <AnchorLink href='#myBuild'><span>02.</span>Experiance</AnchorLink> </motion.li>
                        <motion.li onClick={setNavstatus} animate={{opacity:1 , x:0}} exit={{opacity:0 , x:'-20px'}} initial={{opacity:0 , x:'-20px'}} transition={{duration:0.5 , delay:3.5}}  > <AnchorLink href='#aboutUs'> <span>03.</span>Work</AnchorLink></motion.li>
                        <motion.li onClick={setNavstatus} animate={{opacity:1 , x:0}} exit={{opacity:0 , x:'-20px'}} initial={{opacity:0 , x:'-20px'}} transition={{duration:0.5 , delay:4}}  > <AnchorLink href='#contact'><span>04.</span>Contact</AnchorLink> </motion.li>
                        <motion.li onClick={handleResumeDownload} animate={{opacity:1 , x:0}} exit={{opacity:0 , x:'-20px'}} initial={{opacity:0 , x:'-20px'}} transition={{duration:0.5 , delay:5}}   className='last-child'  >Resume</motion.li>   
                   </ul>
                    <motion.ul animate={{opacity:1 , x:0}} exit={{opacity:0 , x:'20px'}} initial={{opacity:0 , x:'20px'}} transition={{duration:0.5 , delay:6}} className='fix-content-slider'>
                        <li><a rel="noreferrer" target='_blank' href='https://www.instagram.com/thereact_devo/'><AiOutlineInstagram /></a></li>
                        <li><a rel="noreferrer" target='_blank' href='https://github.com/DevoUmair'><FiGithub /></a></li>
                        <li><a rel="noreferrer" target='_blank' href='https://www.linkedin.com/in/umair-lafir'><FiLinkedin /></a></li>
                        <li><a rel="noreferrer" target='_blank' href='https://twitter.com/CllUmair'><FiTwitter /></a></li>
                        <li><a rel="noreferrer" target='_blank' href='https://wa.me/94776344127'><AiOutlineWhatsApp /></a></li>
                    </motion.ul>
            </motion.div>
          </div>

        
        }

        

    </div>
  )
}

export default Navslider