import React from 'react'
import './Banner.css'
import { motion } from 'framer-motion'
import { useOurConetext } from '../../context/PortfolioContext';

function Banner() {
  const {navStatus} = useOurConetext();

  return (
    <main>
             {
               !navStatus && (
                  <div className='content'>
                        <motion.p animate={{opacity:1 , y:0}} exit={{opacity:0 , y:0}} initial={{opacity:0 , y:'20px'}} transition={{duration:0.5 , delay:0}} className='hello'>I am,</motion.p>
                        
                         <motion.h2 animate={{opacity:1 , y:0}} exit={{opacity:0 , y:0}} initial={{opacity:0 , y:'20px'}} transition={{duration:0.5 , delay:0.5}} className='name' >Umair Lafir</motion.h2>
                  
                        <motion.h2 animate={{opacity:1 , y:0}} exit={{opacity:0 , y:0}} initial={{opacity:0 , y:'20px'}} transition={{duration:0.5 , delay:1}} className='role' >Full Stack Devoloper.</motion.h2>
                        
               
                        <motion.p animate={{opacity:1 , y:0}} exit={{opacity:0 , y:0}} initial={{opacity:0 , y:'20px'}} transition={{duration:0.5 ,delay:1.5 }} className='descrip' >I’m a software engineer specializing in building web based aplication using modern technologies. Currently, I’m focused on App devolopment, 
                          Both <span>IOS and Andriod </span></motion.p>
                        <motion.button animate={{opacity:1 , y:0}} exit={{opacity:0 , y:'20px'}} initial={{opacity:0 , y:'20px'}} transition={{duration:0.5 , delay:2}} ><a href='https://wa.me/94776344127' target='_blank' rel="noreferrer" >Hire Me</a> </motion.button>  
                  </div>
               )
             }
    </main>
  )
}

export default Banner