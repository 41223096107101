import React, { useEffect } from 'react'
import './Notworth.css'
import {AiOutlineFolder} from 'react-icons/ai'
import {FiGithub} from 'react-icons/fi'
import AOS  from 'aos'
import 'aos/dist/aos.css'

function Notworthy() {

  useEffect(() => {
    AOS.init({})
  },[])

  return (
    <div className='not-worthy'>
          <div className='head'>
                  <h2>Other Noteworthy Projects</h2>
                  <p>View the archive</p>
          </div>
          <div className='net-content'>

               <div className='each-net' data-aos='fade-right'>
                   <ul className='socila-links'>
                     <li><AiOutlineFolder /></li>
                     <li><FiGithub /></li>
                   </ul>
                   <h3>Add to cart</h3>
                   <p>This one is a basic e-commerce project such as authentication for admin and customer add cart to thre products and order product </p>
                   <ul className='each-links'>
                     <li>Firebase</li>
                     <li>React</li>
                     <li>CSS</li>
                   </ul>
               </div>

               <div className='each-net'  data-aos='fade-up'>
                   <ul className='socila-links'>
                     <li><AiOutlineFolder /></li>
                     <li><FiGithub /></li>
                   </ul>
                   <h3>Whatsapp Clone</h3>
                   <p>This whatsapp clone created using basic MERN technolgy. Authentication created using firebase for handle users and messages send built using mongo db</p>
                   <ul className='each-links'>
                     <li>Firebase</li>
                     <li>React Js</li>
                     <li>Mongo Db</li>
                     <li>Node JS</li>
                   </ul>
               </div>

               <div className='each-net'  data-aos='fade-left'>
                   <ul className='socila-links'>
                     <li><AiOutlineFolder /></li>
                     <li><FiGithub /></li>
                   </ul>
                   <h3>Recipie app</h3>
                   <p>This project created using Api. Fetch foods usinig public API also user can saw each food recipies as well</p>
                   <ul className='each-links'>
                     <li>Firebase</li>
                     <li>React</li>
                     <li>API</li>
                   </ul>
               </div>

               <div className='each-net'  data-aos='fade-right'>
                   <ul className='socila-links'>
                     <li><AiOutlineFolder /></li>
                     <li><FiGithub /></li>
                   </ul>
                   <h3>Authentication</h3>
                   <p>Built a basic login register form using firebase and all the error functionalities enable using react context API</p>
                   <ul className='each-links'>
                     <li>Firebase</li>
                     <li>React</li>
                   </ul>
               </div>
               
          </div>    
    </div>
  )
}

export default Notworthy