import React  from 'react'
import './Nav.css'
import { motion } from 'framer-motion'
import { useOurConetext  } from '../../context/PortfolioContext';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import logo from '../../assets/MYLOGO.png'
import jsPDF from 'jspdf'
import myCv from '../../assets/UmairCv.pdf'

function Nav() {
  const {navStatus , setNavStatus} = useOurConetext();

  const handleResumeDownload = () => {
               var doc = new jsPDF("portrait" , 'px' , 'a4' , 'false')
               doc.addImage(myCv ,'PDF' , 65,20,500 , 400)
               doc.save('UmairCv.PDF')
  }

  return (
    <nav>
        <div className='Nav-continer'>
             <img  src={logo} animate={{opacity:1 , y:0}} exit={{opacity:0 , y:'-20px'}} initial={{opacity:0 , y:'-20px'}} transition={{duration:0.5 , delay:0}} alt='umair-logi'/>
             <motion.ul animate={{opacity:1 , x:0}} exit={{opacity:0 , x:'-20px'}} initial={{opacity:0 , x:'-20px'}} transition={{duration:0.5 , delay:0}}>
                 <li ><AnchorLink offset={() => 100} href='#aboutUs'><span>01.</span>About</AnchorLink></li>
                 <li ><AnchorLink href='#myBuild'><span>02.</span>Experiance</AnchorLink></li>
                 <li ><AnchorLink href='#testi'><span>03.</span>Work</AnchorLink></li>
                 <li ><AnchorLink href='#contact'><span>04.</span>Contact</AnchorLink></li>
                 <li onClick={handleResumeDownload} className='last-child'>Resume</li>
             </motion.ul>
             <div onClick={() => {setNavStatus(!navStatus)}} className={navStatus ? 'active-nav-icon' : 'nav-icon'}>
                <div></div>
                <div></div>
                <div></div>
             </div>
        </div>
    </nav>
  )
}

export default Nav